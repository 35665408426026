html {
  height: -webkit-fill-available;
}

/* Avoid Chrome to see Safari hack */
@supports (-webkit-touch-callout: none) {
  body {
    /* The hack for Safari */
    min-height: -webkit-fill-available;
  }
}

nav {
  width: 450px;
  min-height: 100vh;
  border-radius: 0 30px 30px 0;
  background-color: #008831;
  z-index: 1000;
}

.navResponsiveRow {
  position: fixed;
  top: 0;
  right: 50px;
  width: 60px;
  height: 60px;
  padding: 10px;
  border-radius: 0 0 10px 10px;
  background-color: #008831;
}

.navResponsiveRow img {
  max-width: 55px !important;
}

.navContainer {
  padding: 2vw;
}

.navLogo {
  display: flex;
  justify-content: center;
  margin-bottom: 2vw;
}

.navLogo img {
  max-width: 150px;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  margin-bottom: 1vw;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
}

li svg {
  margin-right: 10px;
}

.active {
  font-weight: bold;
  background-color: #8ec83a;
}

@media (max-width: 1080px) {
  nav {
    position: fixed;
    width: 80%;
  }

  .navResponsiveRow {
    right: 10px;
  }
}
