body {
  background-color: #0d3a1f;
}

a {
  color: inherit;
  /* Utilise la couleur de texte de l'élément parent */
  text-decoration: none;
  /* Supprime le soulignement */
}

a:hover,
a:active,
a:focus {
  color: inherit;
  /* Garde la même couleur au survol, au clic et au focus */
  text-decoration: none;
  /* Supprime le soulignement au survol, au clic et au focus */
}

main {
  display: flex;
  height: 100vh;
}

.dashboardContainer {
  padding: 2vw;
  width: 100%;
}

.dashboardHeaderSearch {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dashboardHeaderSearchInput {
  margin-right: 5%;
}

.dashboardHeader h1,
.dashboardHeaderSearch h1 {
  color: white;
  font-size: 2.5rem;
  line-height: 1rem;
}

.dashboardHeader h1 span,
.dashboardHeaderSearch h1 span {
  color: white;
  font-weight: bold;
  font-style: italic;
}

.dashboardHeader p,
.dashboardHeaderSearch p {
  color: #A0A0A0;
}

.dashboardHeader p span,
.dashboardHeaderSearch p span {
  color: #FF5F5F;
}

@media (max-width: 1080px) {
  .dashboardHeaderSearch {
    justify-content: center;
    flex-wrap: wrap;
  }

  .dashboardHeader h1,
  .dashboardHeaderSearch h1 {
    line-height: 2rem;
  }
}

.dashboardHomeStats {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.dashboardHomeStatsItem {
  background-color: #008831;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  border-radius: 10px;
  padding: 1vw;
  width: 30%;
  cursor: pointer;
  transition: 0.5s;
  margin: 10px;
  flex: 1 1 30%;
}

.dashboardHomeStatsHead h3 {
  color: white;
  font-size: 1.3rem;
  line-height: 1.3rem;
}

.dashboardHomeStatsDetails p {
  color: white;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.dashboardHomeChart {
  width: 100%;
  height: 500px;
}

@media (max-width: 1080px) {
  .dashboardHomeStats {
    justify-content: center;
    text-align: center;
  }

  .dashboardHomeStatsItem {
    flex-wrap: wrap;
  }

  .dashboardHomeStatsHead p, .dashboardHomeStatsDetails p {
    line-height: 16px;
    margin-top: -10px;
  }

  .dashboardHomeStatsDetails {
    width: 100%;
  }
}


.dashboardEventsRow {
  width: 100%;
  margin-top: 50px;
}

.dashboardEventsRow h2 {
  color: white;
  font-size: 1.5rem;
  line-height: 1rem;
}

.dashboardEventsList {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  flex-wrap: wrap;
  width: 100%;
}

.dashboardEventsList article {
  background-color: #008831;
  color: white;
  border-radius: 10px;
  padding: 1vw;
  width: 30%;
  cursor: pointer;
  transition: 0.5s;
  margin: 10px;
  flex: 1 1 30%;
}

.dashboardEventsList article:hover {
  background-color: #0B963D;
  transform: scale(1.02);
  cursor: pointer;
}

.dashboardEventDetails {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  width: 100%;
}

.dashboardEventResume {
  text-align: start;
  line-height: 8px;
}

.dashboardEventState {
  background-color: #8EC83A;
  padding: 1px 5px;
  border-radius: 5px;
  font-size: 12px;
}

.dashboardEventCount {
  color: #0d3a1f;
  font-size: 36px;
  line-height: 0;
}

.dashboardEventCount span {
  font-size: 48px;
  color: white;
  font-weight: bold;
  line-height: 0;
}

.dashboardEventTitle {
  color: white;
  font-size: 24px;
  line-height: 0;
}

.dashboardNewEventButton {
  background-color: #008831;
  outline: none;
  border: none;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  margin: 10px 0;
  transition: 0.5s;
}

.dashboardNewEventButton:hover {
  background-color: #0B963D;
}

.dashboardDeleteEventButton {
  background-color: #FF5F5F;
  outline: none;
  border: none;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  margin: 10px 0;
  transition: 0.5s;
}

.dashboardDeleteEventButton:hover {
  background-color: #fa3c3c;
}

.dashboardEditEventContainer {
  display: inline-flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
}

.dashboardEditEventContainer h2 {
  color: white;
  font-size: 1.5rem;
  line-height: 1rem;
}

.dashboardEventEditRow {
  width: 50%;
}

.dashboardEventPrincingRow {
  width: 45%;
}

.dashboardEventPricingList {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  flex-wrap: wrap;
  width: 100%;
}

.dashboardEventPricingList article {
  background-color: #008831;
  color: white;
  border-radius: 10px;
  padding: 1vw;
  width: 100%;
  cursor: pointer;
  transition: 0.5s;
  margin: 10px;
}

.dashboardEventPricingList article:hover {
  background-color: #0B963D;
  transform: scale(1.02);
  cursor: pointer;
}

.dashboardNewEventTextarea {
  width: 100%;
  height: 100px;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #A0A0A0;
  color: white;
  outline: none;
  resize: none
}

.dashboardBookingItemEditQuantity button {
  background-color: white;
  color: black;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  transition: 0.5s;
}

.dashboardBookingItemEditQuantity input {
  background-color: white;
  color: black;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  margin: 5px;
  width: 40px;
}

.dashboardTable {
  display: inline-flex;
  justify-content: space-between;
  align-items: start;
  flex-wrap: wrap;
  width: 100%;
}

.dashboardBookingItemNew {
  background-color: #A0A0A0;
  text-align: center;
  color: white;
  border-radius: 10px;
  flex: 1 1 500px;
  margin: 10px;
  cursor: pointer;
  transition: 0.5s;
  padding: 5px;
}

.dashboardBookingItem {
  background-color: #008831;
  color: white;
  border-radius: 10px;
  flex: 1 1 500px;
  margin: 10px;
  cursor: pointer;
  transition: 0.5s;
}

.dashboardBookingItem:hover {
  background-color: #0B963D;
  transform: scale(1.02);
  cursor: pointer;
}

.dashboardBookingItemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  margin: 10px 0;
  line-height: 15px;
}

.dashboardBookingItemContainer p {
  color: #A0A0A0;
}

.dashboardBookingItemButton {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dashboardBookingItemButtonCancel {
  background-color: #FF5F5F;
  outline: none;
  border: none;
  color: white;
  border-radius: 10px;
  flex: 1 1 500px;
  margin: 10px;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 10px;
  transition: 0.5s;
}

.dashboardBookingItemButtonTotal {
  background-color: #0B963D;
  outline: none;
  border: none;
  color: white;
  border-radius: 10px;
  flex: 1 1 500px;
  margin: 10px;
  padding: 10px;
  font-size: 1.5rem;
  cursor: pointer;
  transition: 0.5s;
}

@media (max-width: 1080px) {
  .dashboardEventsList article {
    width: 100%;
    flex: 1 1 100%;
  }

  .dashboardBookingItemContainer {
    flex-wrap: wrap;
    justify-content: center;
  }

  .dashboardBookingItemButton {
    flex-wrap: wrap;
  }

  .dashboardBookingItemButtonCancel,
  .dashboardBookingItemButtonTotal {
    font-size: 16px;
  }
}