a {
  color: inherit; /* Utilise la couleur de texte de l'élément parent */
  text-decoration: none; /* Supprime le soulignement */
}

a:hover, a:active, a:focus {
  color: inherit; /* Garde la même couleur au survol, au clic et au focus */
  text-decoration: none; /* Supprime le soulignement au survol, au clic et au focus */
}

.publicSection {
  background-color: #fff;
  min-height: 100vh;
  padding: 0 10vw;
}

.publicSectionContainer {
  width: 100%;
}

.publicNav {
  padding: 20px 0;
  width: 100%;
}

.publicNavContainer {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.publicNavImg {
  max-width: 100px;
}

.publicNavContainer button {
  padding: 10px 20px;
  margin-left: 50px;
  border: none;
  outline: none;
  color: white;
  border-radius: 30px;
  font-size: 1rem;
  transition: 0.5s;
  cursor: pointer;
}

.publicNavContainer button:first-child {
  background-color: transparent;
  color: #383838;
}

.publicNavContainer button:last-child {
  background-color: #0B963D;
  font-weight: bold;
  padding: 10px 30px;
}

.publicNavContainer button:last-child:hover {
  background-color: #0D3A1F;
}

.publicNavContainer button svg {
  margin-right: 5px;
}

.publicHeader {
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  width: 100%;
  min-height: 50vh;
  text-align: center;
}

.publicHeader h1 {
  font-size: 3rem;
  font-weight: normal;
}

.publicHeader h1 span {
  font-weight: bold;
  color: #0B963D;
}

.publicHeader p {
  font-size: 0.8rem;
  font-weight: normal;
  color: #A0A0A0;
}

.publicHeaderButton button {
  background-color: #0B963D;
  padding: 10px 20px;
  margin: 10px;
  border: none;
  outline: none;
  color: white;
  border-radius: 30px;
  font-size: 1rem;
  transition: 0.5s;
  cursor: pointer;
}

.publicHeaderButton button:last-child {
  background-color: #fff;
  border: #0B963D 2px solid;
  color: #0B963D;
}

.publicHeaderButton button:hover {
  background-color: #0D3A1F;
  color: white;
}

.publicHeaderButton button:last-child:hover {
  border: #0D3A1F 2px solid;
  background-color: #fff;
  color: #0D3A1F;
}

.publicPartners h6 {
  font-size: 0.7rem;
  color: #383838
}

.publicPartners img {
  max-height: 100px;
  margin-right: 30px;
}

.publicEvent {
  background-color: #0B963D;
  border-radius: 30px;
  margin: 10vh 0;
}

.publicEvent article {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.publicEvent article img {
  max-width: 50%;
  height: 100%;
  border-radius: 30px;
}

.publicEventDetails {
  padding: 50px;
}

.publicEventHeader {
  color: white;
  line-height: 0;
}

.publicEventHeader h4{
  font-size: 1.5rem;
}

.publicEventHeader p{
  font-size: 0.8rem;
}

.publicEventDescription {
  color: white;
  font-size: 1rem;
  margin: 30px 0;
}

.publicEventButton button {
  background-color: #fff;
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  outline: none;
  color: #0B963D;
  border-radius: 30px;
  font-size: 1rem;
  transition: 0.5s;
  cursor: pointer;
}

.publicEventButton button:last-child {
  background-color: #0B963D;
  border: #fff 2px solid;
  color: #fff;
}

.publicEventButton button:hover {
  background-color: #0D3A1F;
  color: white;
}

.publicEventButton button:last-child:hover {
  border: #0D3A1F 2px solid;
  background-color: #0B963D;
  color: #0D3A1F;
}

.publicEventNothing { 
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0B963D;
  border-radius: 30px;
  margin: 10vh 0;
  width: 100%;
}

.publicSocial {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 0 10vh 0;
}

.publicSocial h2 {
  font-size: 3rem;
  font-weight: normal;
}

.publicSocial h2 span {
  font-weight: bold;
  color: #0B963D;
}

.publicSocial p {
  font-size: 0.8rem;
  font-weight: normal;
  color: #A0A0A0;
}

.publicSocial img {
  max-height: 100px;
  margin-right: 30px;
}

.publicFooter {
  background-color: #0B963D;
  border-radius: 30px 30px 0 0;
  padding: 30px;
}

.publicFooterContainer {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  width: 100%;
}

.publicFooterLinks a {
  margin-right: 30px;
}

.publicForm {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 72.5vh;
}

.publicForm h2 {
  font-size: 3rem;
  font-weight: normal;
  margin-right: 30px;
}

.publicForm h2 span {
  font-weight: bold;
  color: #0B963D;
}

.publicForm p {
  font-size: 0.8rem;
  font-weight: normal;
  line-height: 1;
  color: #A0A0A0;
  margin-right: 30px;
}

.publicFormHead {
  min-width: 45%;
}

.publicFormStepper {
  width: 100%;
  text-align: center;
}

.publicFormStepper h6 {
  font-size: 1rem;
  color: #383838;
}

.publicFormStepper h6 a {
  font-size: 1rem;
  color: #0B963D;
}

.publicFormButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.publicFormButton button {
  padding: 30px 50px;
  margin: 10px;
  border: none;
  outline: none;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: bold;
  transition: 0.5s;
  cursor: pointer;
}

.publicFormButton .greenButton {
  background-color: #0B963D;
  color: white;
}

.publicFormButton .greenButton:hover {
  background-color: #0D3A1F;
  color: white;
}

.publicFormButton .redButton {
  background-color: #fff;
  border: #FF5F5F 2px solid;
  color: #FF5F5F;
}

.publicFormButton .redButton:hover {
  border: #FF5F5F 2px solid;
  background-color: #FF5F5F;
  color: white;
}

.alreadyHaveAccount {
  cursor: pointer;
}

.publicFormInputs input , .publicFormInputs textarea {
  width: 75%;
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-bottom: #0B963D 2px solid;
  outline: none;
  transition: 0.5s;
}

.publicFormInputs input:focus, .publicFormInputs textarea:focus {
  border-bottom: #0D3A1F 2px solid;
}

.publicFormule {
  width: 100%;
}

.publicFormuleItem {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #0B963D;
  margin: 10px 0;
  border-radius: 10px;
  text-align: left;
}

.publicFormuleItemHead {
  padding: 10px 20px;
}

.publicFormuleItemName {
  display: inline-flex;
  align-items: center;
}

.publicFormuleItemHead p:first-child {
  font-size: 1.2rem;
  line-height: 5px;
  font-weight: bold;
  color: white;
  margin-right: 10px;
}

.publicFormuleItemHead p:last-child {
  font-size: 0.8rem;
  color: #d4d4d4;
  line-height: 14px;
  margin-right: 0;
}

.publicFormuleSelector {
  min-width: 130px;
  padding: 10px 20px;
}

.publicFormuleSelector button {
  background-color: #fff;
  padding: 10px;
  margin: 5px 0;
  border: none;
  outline: none;
  color: #0B963D;
  font-weight: bold;
  border-radius: 5px;
  font-size: 1rem;
  transition: 0.5s;
  cursor: pointer;
}

.publicFormuleSelector input {
  width: 30px;
  padding: 10px;
  margin: 10px;
  border: none;
  outline: none;
  border-radius: 5px;
}

.publicBookingList {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  flex-wrap: wrap;
  width: 100%;
}

.publicBookingList article {
  background-color: #008831;
  color: white;
  border-radius: 10px;
  padding: 1vw;
  width: 30%;
  cursor: pointer;
  transition: 0.5s;
  margin: 10px;
  flex: 1 1 30%;
}

.publicBookingList article:hover {
  background-color: #0B963D;
  transform: scale(1.02);
  cursor: pointer;
}

.publicBookingListDetails {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  width: 100%;
}

.publicBookingListInfo div {
  margin: 0 5px;
}

.publicEditConfirmButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #0B963D;
  padding: 10px 0;
  outline: none;
  border: none;
  color: white;
  border-radius: 5px;
  margin: 20px 0px;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  transition: 0.5s;
}

.publicEditConfirmButton:hover {
  background-color: #0D3A1F;
}

.accountPreferenceItem {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px 0;
  border-radius: 10px;
  text-align: left;
}

.accountPreferenceItem h3 {
  max-width: 60%;
}

.publicPreferenceButton {
  display: inline-flex;
  justify-content: right;
  align-items: center;
}

.publicPreferenceButton button {
  padding: 30px 50px;
  margin: 10px;
  border: none;
  outline: none;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: bold;
  transition: 0.5s;
  cursor: pointer;
}

.publicPreferenceButton .greenButton {
  background-color: #0B963D;
  color: white;
}

.publicPreferenceButton .greenButton:hover {
  background-color: #0D3A1F;
  color: white;
}

.publicPreferenceButton .redButton {
  background-color: #fff;
  border: #FF5F5F 2px solid;
  color: #FF5F5F;
}

.publicPreferenceButton .redButton:hover {
  border: #FF5F5F 2px solid;
  background-color: #FF5F5F;
  color: white;
}

@media (max-width: 768px) {
  .publicNavContainer button:last-child {
    display: none;
  }

  .publicHeader {
    min-height: 30vh;
  }

  .publicHeader h1 {
    font-size: 1.6rem;
    font-weight: normal;
  }

  .publicPartners img {
    max-height: 60px;
    margin-right: 15px;
  }
  
  .publicEvent article img {
    display: none;
  }

  .publicEventDetails {
    padding: 20px;
  }

  .publicEventButton {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .publicEventButton button {
    margin: 10px;
  }

  .publicFormHead {
    display: none;
  }

  .publicFormuleItem {
    flex-wrap: wrap;
    justify-content: center;
  }

  .publicFormuleItemHead p:first-child {
    text-align: center;
  }

  .publicFormuleItemHead p:last-child {
    display: none;
  }

  .publicFormInputs input , .publicFormInputs textarea {
    width: 90%;
  }

  .publicFormButton {
    flex-wrap: wrap;
  }

  .publicBookingList article {
    width: 100%;
  }

  .publicFooterContainer {
    text-align: center;
    flex-wrap: wrap;
  }

  .publicFooterLinks {
    display: inline-flex;
    width: 100%;
    flex-wrap: wrap;
  }

  .publicFooterLinks a {
    margin: 5px;
    width: 100%;
  }
}


/* Authentification */

.authSection {
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: url("../../assets/imgs/authBackground.jpg") no-repeat center
    center / cover;
}

.authSection::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.authRow {
  position: relative;
  background-color: #fff;
  width: 50%;
  border-radius: 0 30px 30px 0;
  z-index: 2;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  height: 100%;
}

.authRow form {
  width: 100%;
  padding: 0 10%;
}

.authRow img {
  max-width: 100px;
  margin-bottom: 5vh;
  cursor: pointer;
}

.authRow h1 {
  font-size: 2rem;
  font-weight: normal;
  margin-bottom: 20px;
}

.authInput {
    display: flex;
    flex-wrap: wrap;
}

.authInput input {
    width: 100%;
    margin: 20px 0;
    font-size: 16px;
    border: none;
    outline: none;
    border-radius: 5px;
}

.authRow button {
  background-color: #008831;
  width: 100%;
  padding: 20px;
  text-align: center;
  border: none;
  outline: none;
  margin: 10px 0;
  color: white;
  font-weight: bold;
  border-radius: 5px;	
  font-size: 1rem;
  transition: 0.5s;
  cursor: pointer;
}

.authRow button:hover {
  background-color: #0D3A1F;
}

@media (max-width: 970px) {
  .authRow {
    width: 100%;
    max-width: 100vw;
    max-height: 100vh;
    border-radius: 0;
  }
}